import tw from "twin.macro"
import ButtonGroup from "../Buttons/ButtonGroup"
import EmailIcon from "../Icons/icon_email.png"
import { isBrowser } from "../../helpers"
import { navigate } from "gatsby"
import { useContext, useState } from "react"
import { AuthContext } from "../../hooks/useAuth"

const Mobile: React.FC<{ email: string }> = ({ email, ...remainingProps }) => {
  if (!email && isBrowser) {
    navigate("/check-registration")
  }
  const { state, dispatch }: any = useContext(AuthContext)
  const currentEmail = email
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleReset = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setIsSubmitting(true)

    fetch(
      `${
        process.env.GATSBY_API_ROOT_URL
      }/auth/send-password-reset-email?Email=${encodeURIComponent(
        email?.toLowerCase()
      )}`,
      {
        method: "GET",
        headers: {
          accept: "*/*",
          authorization: `Bearer ${state?.token}`,
        },
      }
    )
      .then(data => {
        if (data.status == 200) {
          setInterval(() => {
            setIsSubmitting(false)
          }, 3000)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleSubmit = (e: { preventDefault: () => void }) => {
    navigate("/password", {
      state: {
        email: currentEmail,
      },
    })
  }
  return (
    <div css={[tw`visible lg:hidden`]} {...remainingProps}>
      <div
        css={[
          tw`py-8 px-5 bg-red-300 flex flex-col justify-center w-full z-50 fixed bottom-0`,
        ]}
      >
        <h6 css={[tw`text-white text-center pb-5`]}>
          Need to reset your password?
        </h6>
        <img
          src={EmailIcon}
          alt="Send Email Icon"
          css={[tw`m-5 h-12 mx-auto`]}
        />

        <h5 css={[tw`text-white text-center pb-8`]}>
          Check your email for a password reset code
        </h5>
        <ButtonGroup
          buttons={[
            {
              value: "OK",
              onClick: handleSubmit,
              dark: true,
              disabled: isSubmitting,
              eventName: "click_ok_reset",
              eventMessage: "store the email address (user id)",
            },
            {
              value: "Resend",
              onClick: handleReset,
              secondary: true,
              loading: isSubmitting,
              eventName: "click_cancel_reset",
              eventMessage: "store the email address (user id)",
            },
          ]}
        />
      </div>
    </div>
  )
}

export default Mobile
