import { PageProps } from "gatsby"
import ResetPassword from "../components/ResetPassword"
import Seo from "../components/SEO"

const ResetPasswordPage: React.FC<PageProps<any, any, { email: string }>> = ({location}) => {
  return (
    <div>
       <Seo title="Reset Password" />
      <ResetPassword.Primary email={location?.state?.email}/>
      <ResetPassword.Mobile email={location?.state?.email}/>
    </div>
  )
}

export default ResetPasswordPage
