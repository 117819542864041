import tw from "twin.macro"
import Modal from "../Modal/Registration"
import ButtonGroup from "../Buttons/ButtonGroup"
import EmailIcon from "../Icons/icon_email.png"
import { AuthContext } from "../../hooks/useAuth"
import { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import { isBrowser } from "../../helpers"

const Primary: React.FC<{ email: string }> = ({ email }) => {
  if (!email && isBrowser) {
    navigate("/check-registration")
  }

  const { state, dispatch }: any = useContext(AuthContext)
  const currentEmail = email
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleReset = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setIsSubmitting(true)

    fetch(
      `${
        process.env.GATSBY_API_ROOT_URL
      }/auth/send-password-reset-email?Email=${encodeURIComponent(
        email?.toLowerCase()
      )}`,
      {
        method: "GET",
        headers: {
          accept: "*/*",
          authorization: `Bearer ${state?.token}`,
        },
      }
    )
      .then(data => {
        if (data.status == 200) {
          setInterval(() => {
            setIsSubmitting(false)
          }, 3000)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleSubmit = (e: { preventDefault: () => void }) => {
    if (isBrowser) {
      navigate("/password", {
        state: {
          email: currentEmail,
        },
      })
    }
  }

  return (
    <Modal
      blue={true}
      open={true}
      modalCss={[tw`w-screen lg:max-w-7xl hidden lg:flex`]}
    >
      <div css={[tw`mx-auto px-36 py-16 bg-red-300 max-w-4xl mb-5 `]}>
        <h6 css={[tw`text-white text-center text-xl mb-5`]}>
          Need to reset your password?
        </h6>
        <img
          src={EmailIcon}
          alt="Send Email Icon"
          css={[tw` h-12 mx-auto mb-5`]}
        />
        <div>
          <h5 css={[tw`text-white text-center mb-8`]}>
            Check your email for a password reset code
          </h5>
        </div>
        <ButtonGroup
          buttons={[
            {
              value: "OK",
              onClick: handleSubmit,
              dark: true,
              disabled: isSubmitting,
              eventName: "click_ok_reset",
              eventMessage: "store the email address (user id)",
            },
            {
              value: "Resend",
              onClick: handleReset,
              secondary: true,
              loading: isSubmitting,
              eventName: "click_cancel_reset",
              eventMessage: "store the email address (user id)",
            },
          ]}
        />
      </div>
    </Modal>
  )
}

export default Primary
